.border-btn {
    border: 1px solid #dee0e6;
    border-radius: 2px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 15px;
    margin: 0 0 10px;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}
a,
button {
    outline: none !important;
    color: #4697b4;
    text-decoration: none !important;
}
a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
